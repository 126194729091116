import "./../App.css";
import React from "react";

class ErrorPage extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="px-10 py-10">
          <img src="logo.png" className="h-10" alt="Kerecis Logo" />
        </div>
        <div className="grid place-items-center px-48 p-10">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Error Processing Payment
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                  Please recheck your card information or try another payment
                  method to continue.
                </p>
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  onClick={() => window.history.back()}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-white bg-kerecis-orange hover:bg-kerecis-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kerecis-orange sm:text-sm"
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
