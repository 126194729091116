import "./../App.css";
import React from "react";

class SuccessPage extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="px-10 py-10">
          <img src="logo.png" className="h-10" alt="Kerecis Logo" />
        </div>
        <div className="grid place-items-center px-48 p-10">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Successful</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                  Thank you for submitting your payment. Your payment is in process. Your transaction ID is <strong>{window.location.search.split("=")[1]}</strong>. Please reach out to accountsreceivable@kerecis.com for questions and reference your transaction ID in your inquiry.
                </p>
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  onClick={() => (window.location.href = "/")}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-white bg-kerecis-orange hover:bg-kerecis-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kerecis-orange sm:text-sm"
                >
                  Submit Another
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SuccessPage;
