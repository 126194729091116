import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import PaymentPage from "./Pages/payment";
import SuccessPage from "./Pages/success";
import ErrorPage from "./Pages/error";
class App extends React.Component {
  
  render() {
    return (
   
        <div className="App">
          <Routes>
            <Route path="/" element={<PaymentPage />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/error" element={<ErrorPage />} />
          </Routes>
        </div>
    
    );
  }
}

export default App;
