/* eslint-disable react-hooks/rules-of-hooks */
import "./../App.css";
import React from "react";
import { Bars } from "react-loading-icons";
import { MailIcon } from "@heroicons/react/solid";
class PaymentPage extends React.Component {
  constructor(props) {
    super(props);

    const searchParams = new URLSearchParams(window.location.search);
    const firstName = searchParams.get("firstName") ?? "";
    const lastName = searchParams.get("lastName") ?? "";
    const email = searchParams.get("email") ?? "";
    const amount = searchParams.get("amount") ?? "";
    const street = searchParams.get("street") ?? "";
    const city = searchParams.get("city") ?? "";
    const state = searchParams.get("state") ?? "";
    const zip = searchParams.get("zip") ?? "";
    const poNumber = searchParams.get("poNumber") ?? "";
    const invoiceNumbers = searchParams.get("invoiceNumbers") ?? "";
    const customerId = searchParams.get("customerId") ?? "";
    const contactName = searchParams.get("contactName") ?? "";
    const phoneNumber = searchParams.get("phoneNumber") ?? "";
    const companyName = searchParams.get("companyName") ?? "";

    this.state = {
      errors: {
        companyName: false,
        email: false,
        street: false,
        city: false,
        state: false,
        zip: false,
        country: false,
        amount: false,
      },
      firstName: firstName,
      lastName: lastName,
      companyName: companyName,
      email: email,
      street: street,
      city: city,
      state: state,
      zip: zip,
      country: "United States",
      poNumber: poNumber,
      invoiceNumbers: invoiceNumbers,
      customerId: customerId,
      contactName: contactName,
      phoneNumber: phoneNumber,
      amount: amount,
      notes: "",
      isSubmitting: false,
      alertMessage: "",
    };
    this.setState = this.setState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.finishSubmit = this.finishSubmit.bind(this);
  }

  componentDidMount() {
    window.CollectJS.configure({
      callback: (token) => {
        this.finishSubmit(token);
      },
    });
  }

  async finishSubmit(response) {
    this.setState({ isSubmitting: true });
    const { isSubmitting, alertMessage, ...formData } = { ...this.state };
    formData.token = response.token;

    const serverResponse = await fetch("https://n43xfz3x2k.execute-api.us-east-1.amazonaws.com/Prod/transaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).catch((error) => (window.location.href = "/error"));

    this.setState({
      isSubmitting: false,
    });
    if (serverResponse.status === 200) {
      const responseData = await serverResponse.json();
      window.location.href =
        "/success?transactionId=" + responseData["transactionId"];
    } else {
      window.location.href = "/error";
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    //============================================================
    // Check the input fields for errors
    //============================================================
    const errors = {
      companyName: this.state.companyName === "",
      email: this.state.email === "",
      street: this.state.street === "",
      city: this.state.city === "",
      state: this.state.state === "",
      zip: this.state.zip === "",
      country: this.state.country === "",
      amount: this.state.amount === "",
    };
    if (
      !(
        errors.companyName ||
        errors.email ||
        errors.street ||
        errors.city ||
        errors.state ||
        errors.zip ||
        errors.country ||
        errors.amount
      )
    ) {
      window.CollectJS.startPaymentRequest();
    }
    this.setState({ errors: errors });
  }
  render() {
    if (this.state.isSubmitting) {
      return (
        <div className="App">
          <div className="px-10 py-10">
            <img src="logo.png" className="h-10" alt="Kerecis Logo" />
          </div>
          <div className="grid place-items-center h-screen">
            <Bars />
          </div>
        </div>
      );
    }
    return (
      <div className="App">
        <form onSubmit={this.handleSubmit}>
          <div className="px-10 py-10">
            <img src="logo.png" className="h-10" alt="Kerecis Logo" />
          </div>
          <div className="grid place-items-center px-48 p-10">
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 text-left">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Kerecis Secure Checkout
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Please process your payment information following the
                    instructions below. Please reach out to
                    accountsreceivable@kerecis.com with any questions.
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          First name
                        </label>

                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          className="mt-1 focus:ring-kerecis-blue focus:border-kerecis-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(event) =>
                            this.setState({ firstName: event.target.value })
                          }
                          value={this.state.firstName}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Last name
                        </label>
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="mt-1 focus:ring-kerecis-blue focus:border-kerecis-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(event) =>
                            this.setState({ lastName: event.target.value })
                          }
                          value={this.state.lastName}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <div className="flex justify-between">
                          <label
                            htmlFor="company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Company
                          </label>
                          <span
                            className="text-sm text-gray-500"
                            id="company-required"
                          >
                            Required
                          </span>
                        </div>
                        <input
                          type="text"
                          name="company"
                          id="company"
                          autoComplete="given-name"
                          className="mt-1 focus:ring-kerecis-blue focus:border-kerecis-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(event) =>
                            this.setState({ companyName: event.target.value })
                          }
                          value={this.state.companyName}
                          aria-invalid="false"
                          aria-describedby="email-error"
                        />
                        {this.state.errors.companyName && (
                          <p className="mt-2 text-sm text-red-600">
                            Company is required
                          </p>
                        )}
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <div className="flex justify-between">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                          <span
                            className="text-sm text-gray-500"
                            id="email-required"
                          >
                            Required
                          </span>
                        </div>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MailIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                            placeholder="you@example.com"
                            onChange={(event) =>
                              this.setState({ email: event.target.value })
                            }
                            value={this.state.email}
                          />
                        </div>
                        {this.state.errors.email && (
                          <p className="mt-2 text-sm text-red-600">
                            Email is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-3 md:gap-6 py-20">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Billing Address
                  </h3>
                  <p className="mt-1 text-sm text-gray-500"></p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <div className="flex justify-between">
                          <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Country
                          </label>
                          <span
                            className="text-sm text-gray-500"
                            id="country-required"
                          >
                            Required
                          </span>
                        </div>
                        <select
                          id="country"
                          name="country"
                          autoComplete="country-name"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-kerecis-blue focus:border-kerecis-blue sm:text-sm"
                          onChange={(event) =>
                            this.setState({ country: event.target.value })
                          }
                          value={this.state.country}
                        >
                          <option>United States</option>
                          <option>Canada</option>
                          <option>Mexico</option>
                        </select>
                        {this.state.errors.country && (
                          <p className="mt-2 text-sm text-red-600">
                            Billing country is required
                          </p>
                        )}
                      </div>

                      <div className="col-span-6">
                        <div className="flex justify-between">
                          <label
                            htmlFor="street-address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Street Address
                          </label>
                          <span
                            className="text-sm text-gray-500"
                            id="street-address-required"
                          >
                            Required
                          </span>
                        </div>
                        <input
                          type="text"
                          name="street-address"
                          id="street-address"
                          autoComplete="street-address"
                          className="mt-1 focus:ring-kerecis-blue focus:border-kerecis-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(event) =>
                            this.setState({ street: event.target.value })
                          }
                          value={this.state.street}
                        />
                        {this.state.errors.street && (
                          <p className="mt-2 text-sm text-red-600">
                            Billing street is required
                          </p>
                        )}
                      </div>

                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="city-address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            City
                          </label>
                          <span
                            className="text-sm text-gray-500"
                            id="street-address-required"
                          >
                            Required
                          </span>
                        </div>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          autoComplete="address-level2"
                          className="mt-1 focus:ring-kerecis-blue focus:border-kerecis-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(event) =>
                            this.setState({ city: event.target.value })
                          }
                          value={this.state.city}
                        />
                        {this.state.errors.city && (
                          <p className="mt-2 text-sm text-red-600">
                            Billing city is required
                          </p>
                        )}
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="region"
                            className="block text-sm font-medium text-gray-700"
                          >
                            State / Province
                          </label>
                          <span
                            className="text-sm text-gray-500"
                            id="region-required"
                          >
                            Required
                          </span>
                        </div>

                        <select
                          id="region"
                          name="region"
                          autoComplete="region-name"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-kerecis-blue focus:border-kerecis-blue sm:text-sm"
                          onChange={(event) =>
                            this.setState({ state: event.target.value })
                          }
                          value={this.state.state}
                        >
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                        {this.state.errors.state && (
                          <p className="mt-2 text-sm text-red-600">
                            Billing state is required
                          </p>
                        )}
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="postal-code"
                            className="block text-sm font-medium text-gray-700"
                          >
                            ZIP / Postal code
                          </label>
                          <span
                            className="text-sm text-gray-500"
                            id="postal-code-required"
                          >
                            Required
                          </span>
                        </div>

                        <input
                          type="text"
                          name="postal-code"
                          id="postal-code"
                          autoComplete="postal-code"
                          className="mt-1 focus:ring-kerecis-blue focus:border-kerecis-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(event) =>
                            this.setState({ zip: event.target.value })
                          }
                          value={this.state.zip}
                        />
                        {this.state.errors.zip && (
                          <p className="mt-2 text-sm text-red-600">
                            Billing postal code is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Order Information
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">Payments made without indicating an invoice or PO number will be applied to the oldest outstanding invoices.</p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="po-number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        PO Number
                      </label>
                      <input
                        type="text"
                        name="po-number"
                        id="po-number"
                        className="mt-1 focus:ring-kerecis-blue focus:border-kerecis-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(event) =>
                          this.setState({ poNumber: event.target.value })
                        }
                        value={this.state.poNumber}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="invoice-number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Invoice Number(s)
                      </label>
                      <input
                        type="text"
                        name="invoice-number"
                        id="invoice-number"
                        className="mt-1 focus:ring-kerecis-blue focus:border-kerecis-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(event) =>
                          this.setState({ invoiceNumbers: event.target.value })
                        }
                        value={this.state.invoiceNumbers}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="customer-id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Customer ID
                      </label>
                      <input
                        type="text"
                        name="customer-id"
                        id="customer-id"
                        className="mt-1 focus:ring-kerecis-blue focus:border-kerecis-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(event) =>
                          this.setState({ customerId: event.target.value })
                        }
                        value={this.state.customerId}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="contact-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contact Name
                      </label>
                      <input
                        type="text"
                        name="contact-name"
                        id="contact-name"
                        className="mt-1 focus:ring-kerecis-blue focus:border-kerecis-blue block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(event) =>
                          this.setState({ contactName: event.target.value })
                        }
                        value={this.state.contactName}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <div className="flex justify-between">
                        <label
                          htmlFor="phone-number"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Phone Number
                        </label>
                        <span
                          className="text-sm text-gray-500"
                          id="phone-number-required"
                        >
                          Required
                        </span>
                      </div>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 flex items-center">
                          <label htmlFor="country" className="sr-only">
                            Country
                          </label>
                          <select
                            id="phone-country"
                            name="phone-country"
                            autoComplete="phone-country"
                            className="focus:ring-kerecis-blue focus:border-kerecis-blue h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                          >
                            <option>US</option>
                            <option>CA</option>
                            <option>EU</option>
                          </select>
                        </div>
                        <input
                          type="text"
                          name="phone-number"
                          id="phone-number"
                          className="focus:ring-kerecis-blue focus:border-kerecis-blue block w-full pl-16 sm:text-sm border-gray-300 rounded-md"
                          placeholder="+1 (555) 987-6543"
                          onChange={(event) =>
                            this.setState({ phoneNumber: event.target.value })
                          }
                          value={this.state.phoneNumber}
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <div>
                        <div className="flex justify-between">
                          <label
                            htmlFor="order-amount"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Order Amount
                          </label>
                          <span
                            className="text-sm text-gray-500"
                            id="order-amount-required"
                          >
                            Required
                          </span>
                        </div>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="text"
                            name="price"
                            id="price"
                            className="focus:ring-kerecis-blue focus:border-kerecis-blue block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="0.00"
                            aria-describedby="price-currency"
                            onChange={(event) =>
                              this.setState({ amount: event.target.value })
                            }
                            value={this.state.amount}
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              USD
                            </span>
                          </div>
                        </div>
                        {this.state.errors.amount && (
                          <p className="mt-2 text-sm text-red-600">
                            Amount is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 sm:col-span-12 py-5">
                    <label
                      htmlFor="comment"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Comments
                    </label>
                    <div className="mt-1">
                      <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        className="shadow-sm focus:ring-kerecis-blue focus:border-kerecis-blue block w-full sm:text-sm border-gray-300 rounded-md"
                        defaultValue={this.state.notes}
                        onChangeonChange={(event) =>
                          this.setState({ notes: event.target.notes })
                        }
                      />
                    </div>
                  </div>
                  <div className="py-10 grid justify-items-end">
                    <button
                      type="submit"
                      className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-kerecis-blue hover:kerecis-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kerecis-blue "
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default PaymentPage;
